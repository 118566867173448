.report-button{
    position: absolute;
    top: 5px;
    left: 10px;
}
#jsmediaversion {
    position: absolute;
    top: 15px;
    left: 45px;
    color: red;
}
#report-title{
    color:white;
    position: absolute;
    top: 40px;
    left: 10px;
}